import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Food from "./components/favorites/food/food.jsx";
import Nav from "./components/nav/nav.jsx";
import About from "./components/about/about.jsx";
import Contact from "./components/contact/contact.jsx";
import Services from "./components/services/services.jsx";
import Header from "./components/header/Header.jsx"

function App() {
  return (
    <BrowserRouter>
      <Nav />
      <Header />
      <br />
      <br />
        <Routes>
          <Route path="/" element={<Food />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/services" element={<Services />}></Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
