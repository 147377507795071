import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cover from '../../covers/cover.jsx';
import { foodData } from './foodDataOriginal.jsx';

function Food() {
  return (
    <Container>
      <Row>
        <br />
        {foodData.map((item, index) => (
          <Col key={index} xs={12} sm={6} md={3} style={{ marginBottom: '1rem', padding: '1rem', gap: '64px;' }}>
            <Cover src={item.src} loading="lazy" style={{ marginTop: item.id === 5 ? '-18%' : '-3%' }} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Food;
