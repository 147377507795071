export const tiers = [
  {
    name: "Basic",
    description: [
      "45 minute shoot",
      "1 location",
      "1 outfit",
      "15 photos",
    ],
    price: "$80",
    image: "/services/performance/jpeg-optimizer_portrait8.jpg"
  },
  {
    name: "Deluxe ",
    description: [
      "90 minute shoot",
      "2 locations",
      "2 outfits",
      "30 photos",
    ],
    price: "$140",
    image: "/services/performance/jpeg-optimizer_portait-color1.jpg"
  },
  {
    name: "Premium",
    description: [
      "180 minute shoot",
      "2-3 locations",
      "Unlimited outfits",
      "50 photos",
    ],
    price: "$240",
    image: "/services/performance/jpeg-optimizer_portrait2.jpg"
  },
]
