import React, { useEffect, useState } from "react"
import { links } from "../data/links/linkData.jsx"
import { Link } from "react-router-dom"
import { Container, Row, Col } from 'react-bootstrap';
import faBars from "../../assets/faBars.svg"
import faTimes from "../../assets/faTimes.svg"
import "./header.css"
import "../nav/styles.css"

const Header = () => {
  const [scrollHeader, setScrollHeader] = useState(false)
  const [showMenu, setShowMenu] = useState(true)
  const [showOverlay, setShowOverlay] = useState(false) // New state for overlay

  const changeHeader = () => {
    if (window.scrollY >= 80) {
      setScrollHeader(true)
    } else {
      setScrollHeader(false)
    }
  }

  const closeMenu = () => {
    setShowMenu(false)
    setShowOverlay(false) // Close the overlay when closing the menu
  }

  useEffect(() => {
    // Function to close the overlay when screen size crosses 768px
    const closeOverlayOnResize = () => {
      if (window.innerWidth >= 768 && showOverlay) {
        setShowOverlay(false)
      }
    }

    window.addEventListener("scroll", changeHeader)
    window.addEventListener("resize", closeOverlayOnResize) // Listen for resize event

    return () => {
      window.removeEventListener("scroll", changeHeader)
      window.removeEventListener("resize", closeOverlayOnResize) // Remove resize event listener
    }
  }, [showMenu, showOverlay])

  return (
    <header className={`${scrollHeader ? "scroll-header" : ""} header`}>
      <nav className="nav__container">

        <Container className={`${showMenu ? "show-menu" : ""} nav__menu`}>
         <Row className="">
          {links.map(({ name, path }, index) => (
            <Col xs={12} sm={6} md={3} style={{ marginBottom: '5rem !important', padding: '1rem', gap: '64px;' }} key={index} className="nav__item">
              <center>
                <Link
                  className="nav__link"
                  to={path}
                  spy={true}
                  hashSpy={true}
                  smooth={true}
                  offset={-60}
                  duration={500}
                  onClick={() => {
                    console.log('hi, ')
                  }}

                  >
                  {name}
                </Link>
              </center>
            </Col>
          ))}
        </Row>
        </Container>

        <div
          className="nav__toggle"
          onClick={() => {
            setShowMenu(!showMenu)
            setShowOverlay(!showOverlay)
          }}
        >
          <img src={faBars} alt="dropdown" className="bars" />
        </div>
      </nav>
      {showOverlay && (
        <div className={`overlay ${showOverlay ? "active" : ""}`}>
          <button
            className="overlay__close-button"
            onClick={() => setShowOverlay(false)}
          >
            <img src={faTimes} style={{ width: "6vw" }} alt="close" />
          </button>
          <nav className="overlay__menu-list">
            <center>
            {links.map(({ name, path }, index) => {
              return (
                <li className="overlay__menu-item nav__item" key={index}>
                  <Link
                    className="nav__link"
                    to={path}
                    spy={true}
                    hashSpy={true}
                    smooth={true}
                    offset={-60}
                    duration={500}
                    onClick={closeMenu}
                  >
                    {name}
                  </Link>
                </li>
              )
            })}
            </center>
          </nav>
        </div>
      )}
    </header>
  )
}

export default Header
