import Portfolio1 from "../../../assets/overview/performance/jpeg-optimizer_portfolio1.jpeg";
import Portfolio2 from "../../../assets/overview/performance/jpeg-optimizer_BWPortfolioOfficial4.jpg";
import Portfolio4 from "../../../assets/overview/performance/jpeg-optimizer_BWPortfolioOfficial3.jpg";
import Portfolio7 from "../../../assets/overview/performance/jpeg-optimizer_BWPortfolioOfficial7.jpg";
import Portfolio8 from "../../../assets/overview/performance/jpeg-optimizer_BWPortfolioOfficial8.jpg";
import Portfolio15 from "../../../assets/overview/performance/jpeg-optimizer_portfolio15.jpeg";
import Portfoliolove from "../../../assets/overview/performance/jpeg-optimizer_PortfolioLove.jpeg";
import Portfoliobw from "../../../assets/overview/performance/jpeg-optimizer_BWPortfolioOfficial11.jpg";
import Portfoliobw2 from "../../../assets/overview/performance/jpeg-optimizer_BWPortfolioOfficial10.jpg";
import Portfoliostart from "../../../assets/overview/performance/jpeg-optimizer_portfolio11.jpeg";
import PortfolioFlowers2 from "../../../assets/overview/performance/jpeg-optimizer_PortfolioFlowers1.jpeg";
import BW1 from "../../../assets/overview/performance/jpeg-optimizer_BW1.jpg";
import BW2 from "../../../assets/overview/performance/jpeg-optimizer_BW3.jpg";
import BW4 from "../../../assets/overview/performance/jpeg-optimizer_BW5.jpg";
import Portfolioidk from "../../../assets/overview/performance/jpeg-optimizer_PortfolioOfficial6.jpeg";
import PortfolioFlowers1 from "../../../assets/overview/performance/jpeg-optimizer_portfolio34.jpeg";



export const foodData = [
  {
    id: 1,
    src: Portfolio1,
  },
  {
    id: 2,
    src: Portfolio2,
  },
  {
    id: 3,
    src: Portfoliolove,
  },
  {
    id: 4,
    src: Portfoliobw,
  },
  {
    id: 5,
    src: PortfolioFlowers1,
  },
  {
    id: 6,
    src: Portfolioidk,
  },
  {
    id: 7,
    src: Portfolio8,
  },
  {
    id: 8,
    src: Portfolio7,
  },
  {
    id: 9,
    src: BW1,
  },
  {
    id: 10,
    src: Portfolio15,
  },
  {
    id: 11,
    src: Portfolio4,
  },
  {
    id: 12,
    src: BW2,
  },
  {
    id: 13,
    src: BW4,
  },
  {
    id: 14,
    src: PortfolioFlowers2,
  },
  {
    id: 15,
    src: Portfoliostart,
  },
  {
    id: 16,
    src: Portfoliobw2,
  },
];
