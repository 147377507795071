import "./styles.css";
import { Container, Row, Col } from 'react-bootstrap';

const Contact = () => {
  return (
    <Container>
      <Row>
        <Col>
          <br />
          <br />
          <center>
            <h2
              className="strokeme"
              style={{
                fontFamily: "Bacasime Antique",
                fontSize: "18px",
                color: "black",
                letterSpacing: "0.02em",
                lineHeight: "1.8", // Adjust the value to make the text taller
                transform: "scale(1,1.15)",
              }}
            >
              <strong
                style={{
                  fontWeight: "bold",
                }}
              >
                <b>COMMERCIAL BOOKINGS/ENQUIRIES</b>
                <br />
                <hr
                  className="strokeme"
                  style={{
                    maxWidth: "480px",
                    transform: "scale(1,2.5)",
                    color: "black",
                  }}
                />
              </strong>
            </h2>
            <p class="contact-paragraph">
              Audrey Roberts
              <br />
              audreymwalker@gmail.com
              <br />
              +1 503-819-1539
              <br />
              +1 503-800-1922
              <br />
              <br />
              <br />
              <br />
            </p>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "space-around",
                margin: "0px 480px",
                marginTop: "38vh",
              }}
            >
              <a href="https://www.redbubble.com/people/audreywalker/shop" target="_blank" rel="noopener noreferrer">
                <i class="fa-solid fa-soap fa-2x black"></i>
              </a>
              <a href="https://www.instagram.com/audreywalker/" target="_blank" rel="noopener noreferrer">
                <i class="fa-brands fa-instagram fa-2x black"></i>
              </a>
              <a href="https://www.linkedin.com/in/audreywalker/" target="_blank" rel="noopener noreferrer">
                <i class="fab fa-linkedin-in fa-2x black"></i>
              </a>
            </div>
          </center>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
