import "./style.css";

const Cover = ({ src, style }) => {
  return (
    <div
      style={style}
      className="w-full border-10 border-gray-400 px-2 py-0 rounded-lg transform transition duration-500 hover:scale-105"
    >
      <div className="card w-70 bg-base-80 shadow-xl image-full ">
        <div>
          <img src={src} alt="Audrey photography" />
        </div>
      </div>
    </div>
  );
};

export default Cover;
