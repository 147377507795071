import React from "react";
import Card from 'react-bootstrap/Card';
import { Container, Row, Col } from 'react-bootstrap';
import { tiers } from '../data/tiers/tierData'; // Import the tiers data

const Services = () => {
  return (
    <Container>
      <br />
      <Row className="g-4">
        {tiers.map((tier, idx) => (
          <Col key={idx} xs={12} lg={4}>
            <Card>
              {tier.image && (
                <Card.Img variant="top" src={tier.image} alt={`Image for ${tier.name}`} />
              )}
              <Card.Body>
                <center>
                  <br />
                  <Card.Title className="services-title" style={{ fontSize: "1.75rem"}}><b><i>{tier.name}</i></b></Card.Title>
                  <br />
                  <Card.Text style={{ color: "#333333" }}>
                    <i>{tier.description.map((item, index) => (
                      <p key={index}>{item}</p>
                    ))}</i>
                  </Card.Text>
                  <br />
                  <Card.Text style={{ color: "#333333", fontSize: "1.25rem" }}><b><i>{tier.price}</i></b></Card.Text>
                </center>
                  <br />
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Services;
