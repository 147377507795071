import "./styles.css";
import { Container, Row, Col } from 'react-bootstrap';
import Audrey from "../../assets/audrey.png";

const About = () => {
  return (
    <Container>
      <Row>
        <br />
        <Col lg={6}>
          <img
            src={Audrey}
            alt="Audrey Roberts"
            style={{ width: "150%", height: "auto", marginTop: "10%" }}
          />
        </Col>

        <Col lg={6} style={{ padding: "80px" }}>
          <center>
            <h2
              className="strokeme"
              style={{
                fontFamily: "Bacasime Antique",
                fontSize: "15px",
                color: "black",
                letterSpacing: "0px",
                lineHeight: "1.5", // Adjust the value to make the text taller
                transform: "scale(1,1.15)",
              }}
            >
              <strong
                style={{
                  fontWeight: "bold",
                }}
              >
                <br />
                <br />
                <b>ABOUT AUDREY ROBERTS</b>
              </strong>
              <br />
            </h2>
            <br />
            <p className="about-paragraph">
              Born and raised in Portland, Oregon, Audrey Roberts is a talented
              mom and photographer. With a lifelong passion for the arts, she
              has been involved in the industry from a young age, working as an
              actress. Her experiences in front of the camera have shaped her
              artistic vision and passion for photography.
              <br />
              <br />
              With a lifelong passion for photography, she has dedicated herself
              to capturing special moments and creating beautiful imagery.
              Audrey finds inspiration in the world around her and the people
              she encounters. Her photographs reflect her unique perspective,
              showcasing her keen eye for detail and her ability to capture the
              essence of her subjects.
              <br />
              <br />
              As a dedicated photographer, Audrey continues to refine her skills
              and explore new techniques to enhance her craft. Her love for
              photography shines through in every image she creates.
              <br />
              <br />
              (Also, she kind of loves New York.) 
              {/* (Remaining content unchanged) */}
            </p>

            {/* Social media icons with spacing and hover effects */}
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "space-around",
                margin: "20px 0",
              }}
            >
              {/* For larger screens */}
              <div className="d-none d-lg-block">
                <a
                  href="https://www.redbubble.com/people/audreywalker/shop"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    margin: "0 10px",
                    transition: "transform 0.6s ease",
                    display: "inline-block", // Use inline-block instead of block
                  }}
                >
                  <i className="fa-solid fa-soap fa-2x black" />
                </a>
                <a
                  href="https://www.instagram.com/audreywalker/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    margin: "0 10px",
                    transition: "transform 0.6s ease",
                    display: "inline-block", // Use inline-block instead of block
                  }}
                >
                  <i className="fa-brands fa-instagram fa-2x black" />
                </a>
                <a
                  href="https://www.linkedin.com/in/audreywalker/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    margin: "0 10px",
                    transition: "transform 0.6s ease",
                    display: "inline-block", // Use inline-block instead of block
                  }}
                >
                  <i className="fab fa-linkedin-in fa-2x black" />
                </a>
              </div>

              {/* For smaller screens */}
              <div className="d-lg-none">
                <a
                  href="https://www.redbubble.com/people/audreywalker/shop"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    margin: "0 10px",
                    display: "inline-block", // Use inline-block instead of block
                  }}
                >
                  <i className="fa-solid fa-soap fa-2x black social-media" />
                </a>
                <a
                  href="https://www.instagram.com/audreywalker/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    margin: "0 10px",
                    display: "inline-block", // Use inline-block instead of block
                  }}
                >
                  <i className="fa-brands fa-instagram fa-2x black social-media" />
                </a>
                <a
                  href="https://www.linkedin.com/in/audreywalker/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    margin: "0 10px",
                    display: "inline-block", // Use inline-block instead of block
                  }}
                >
                  <i className="fab fa-linkedin-in fa-2x black social-media" />
                </a>
              </div>
            </div>
          </center>
        </Col>
      </Row>

      <br />
      <br />
    </Container>
  );
};

export default About; 
