import React, { useState } from 'react';
import "./styles.css"
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import AudreyRoberts from '../../assets/audrey-roberts.png';

function Nav() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Container>
      <center>
        <br />
        <br />
        <img src={AudreyRoberts} id="logoAudreyRoberts" alt="Audrey Roberts" />
      </center>
    </Container>
  );
}

export default Nav;
