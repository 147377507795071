/* These links populate the header of the website 
(the top bit). 

It is recommended not to touch these, as it'll look weird if you add/remove a component here but haven't reflected it throughout the website. 

How to remove a piece: 
  1. Remove one of the corresponding pairs here. 
  2. Remove the corresponding piece (e.g. for "Home" you would remove <Home /> within App.jsx)

How to add a piece:
  0. Design the piece/component you are wanting to add before-hand :) (or ask me for help!) It's easier to often start a new react application for this before moving it in, so you know how you'll want it to look like and that everything works fine.
  1. Add the corresponding pair here, as in the examples below. (preferably in the respective order you want the components to be displayed)
  2. Import and add the corresponding component to App.jsx */

export const links = [
  {
    name: "OVERVIEW",
    path: "",
  },
  {
    name: "ABOUT",
    path: "/about",
  },
  {
    name: "CONTACT",
    path: "/contact",
  },
  {
    name: "SERVICES",
    path: "/services",
  }
]
